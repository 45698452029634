import styled from "styled-components";
import { Divider, Typography, useTheme } from "@mui/material";
import dxTextArea from "devextreme/ui/text_area";
import { Form, SimpleItem, EmptyItem } from "devextreme-react/ui/form";
import { useQuery } from "@tanstack/react-query";
import MonolithCaseService from "../../../api/Monolith/Cases/index.js";
import { useAuth } from "../../../contexts/AuthContext.js";
import { useParams } from "react-router-dom";
import { DevexEditors } from "../../../utils/devex-editors.js";
import { useEffect, useMemo, useRef } from "react";
import { useSnackbar } from "notistack";
import { useNewRequest } from "./NewRequestContext.js";

const RandomNameSelector = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(props) => props.theme.palette.primary.main};
  user-select: none;

  margin-top: 3px;
  width: fit-content;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const DetailsForm = ({
  requestDetailsForm,
  handleChange,
  caseTypes,
  customFields,
}) => {
  return (
    <>
      {useMemo(() => {
        return (
          <Form ref={requestDetailsForm} colCount={2}>
            <SimpleItem
              dataField="case_number"
              label={{ text: "Case/Reference Number" }}
              editorOptions={{
                placeholder: "Enter your case or reference number",
                onValueChanged: (e) => handleChange({ case_number: e.value }),
              }}
            />
            <SimpleItem
              dataField="case_type"
              label={{ text: "Case Type" }}
              editorType="dxSelectBox"
              isRequired={true}
              editorOptions={{
                items: caseTypes,
                displayExpr: "case_type",
                valueExpr: "case_type",
                dropDownOptions: {
                  height: 250,
                },
                onValueChanged: (e) => handleChange({ case_type: e.value }),
              }}
            />
            {customFields &&
              customFields.map((field) => {
                const customDataField = `custom_field_${field.field_id}`;
                const placeholder =
                  field.description ||
                  (field.editor_type === "textBox"
                    ? "Enter text..."
                    : "Select...");
                return (
                  <SimpleItem
                    key={customDataField}
                    dataField={customDataField}
                    editorType={DevexEditors[field.editor_type]}
                    // isRequired={attribute.is_required === 1}
                    label={{ text: field.field_name }}
                    isRequired={field.is_required === 1}
                    editorOptions={{
                      items: JSON.parse(field.options || "[]"),
                      dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                      useMaskBehavior: true,
                      placeholder: placeholder || "",
                      hint: placeholder || "",
                      //   displayFormat: getDateFormat(false, false),
                      showClearButton: field.editor_type !== "textBox",
                      pickerType: "calendar",
                      showDropDownButton: true,
                      multiline: false,
                      showSelectionControls:
                        field.editor_type === "tagBox" ? true : false,
                      onValueChanged: (e) =>
                        handleChange({ [customDataField]: e.value }),
                    }}
                  ></SimpleItem>
                );
              })}
            {customFields.length % 2 && <EmptyItem />}
            <SimpleItem
              dataField="description"
              editorType="dxTextArea"
              colSpan={2}
              isRequired={true}
              editorOptions={{
                height: 200,
                placeholder:
                  "Please provide a DETAILED description of what you need for this case or listed evidence items.",
                onValueChanged: (e) => handleChange({ description: e.value }),
              }}
            />
          </Form>
        );
      }, [])}
    </>
  );
};

const CaseDetails = styled(({ className, children, validateData }) => {
  const theme = useTheme();
  const { setFormData } = useNewRequest();
  const { currentUser } = useAuth();
  const { tenantSlug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const requestNameForm = useRef(null);
  const requestDetailsForm = useRef(null);

  const monolithTenant = currentUser.tenants.find(
    (tenant) => tenant.tenant_slug === tenantSlug
  );

  const { isLoading, data } = useQuery({
    queryKey: [
      "cases:elements",
      {
        monolith_tenant: monolithTenant.monolith_tenant,
        include_types: true,
        include_custom_fields: true,
      },
    ],
    queryFn: () =>
      MonolithCaseService.getCaseElements({
        monolith_tenant: monolithTenant.monolith_tenant,
        include_types: true,
        include_custom_fields: true,
      }),
    enabled: !!currentUser,
  });

  const _validateData = () => {
    if (
      !requestNameForm.current.instance.validate().isValid ||
      !requestDetailsForm.current.instance.validate().isValid
    ) {
      // show snackbar
      enqueueSnackbar("Please fill out all required fields.", {
        variant: "error",
      });
      return { isValid: false };
    }
    return { isValid: true };
  };

  const setRandomName = () => {
    import("../../../utils/generate-random-string.js").then(
      ({ default: generateRandomString }) => {
        const randomName = generateRandomString(
          Math.floor(Math.random() * 10000) % 9 ? 2 : 3
        );

        requestNameForm.current.instance.option("formData", {
          request_name: randomName,
        });
      }
    );
  };

  const handleChange = (data) => {
    setFormData((prevState) => {
      const newCaseDetails = { ...prevState.caseDetails, ...data };

      // Extract and process custom fields
      const customFieldEntries = Object.entries(newCaseDetails).filter(
        ([key]) => key.startsWith("custom_field_")
      );

      const updatedCustomFields = customFieldEntries.reduce(
        (acc, [key, value]) => {
          if (value !== null && value !== undefined) {
            const fieldID = parseInt(key.split("custom_field_")[1]);
            const field = customFields.find((cf) => cf.field_id === fieldID);
            if (field) {
              acc.push({ ...field, value });
            }
          }
          delete newCaseDetails[key];
          return acc;
        },
        []
      );

      // Update custom_fields array
      if (Array.isArray(newCaseDetails.custom_fields)) {
        // Iterate through existing custom fields and update them
        newCaseDetails.custom_fields = newCaseDetails.custom_fields.map(
          (field) =>
            updatedCustomFields.find((f) => f.field_id === field.field_id) ||
            field
        );

        // Add new custom fields
        updatedCustomFields.forEach((newField) => {
          if (
            !newCaseDetails.custom_fields.some(
              (f) => f.field_id === newField.field_id
            )
          ) {
            newCaseDetails.custom_fields.push(newField);
          }
        });
      } else {
        newCaseDetails.custom_fields = updatedCustomFields;
      }

      return { ...prevState, caseDetails: newCaseDetails };
    });
  };

  useEffect(() => {
    validateData.current = _validateData;
  }, []);

  if (isLoading) return null;

  const { caseTypes, customInquiryFields } = data;

  const customFields = customInquiryFields.filter(
    (field) => field.external_enabled
  );

  return (
    <div className={className}>
      <div style={{ margin: "auto" }}>
        <Typography variant="h3" gutterBottom display="inline">
          Request Name
        </Typography>
        <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
          Give your request a name so you can better identify it.
        </div>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
            gap: 15,
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Form ref={requestNameForm} colCount={2} width="100%">
            <SimpleItem
              dataField="request_name"
              label={{ text: "Request Name" }}
              isRequired={true}
              editorOptions={{
                placeholder: "Name your request.",
                onValueChanged: (e) => handleChange({ request_name: e.value }),
              }}
            />
          </Form>
        </div>
        <RandomNameSelector onClick={setRandomName}>
          Generate Random Name
        </RandomNameSelector>
        <Divider style={{ margin: "25px 0px" }} />
        <Typography variant="h3" gutterBottom display="inline">
          Case Details
        </Typography>
        <div style={{ color: theme.palette.text.secondary, marginTop: 5 }}>
          Tell us about your case.
        </div>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <DetailsForm
            requestDetailsForm={requestDetailsForm}
            handleChange={handleChange}
            caseTypes={caseTypes}
            customFields={customFields}
          />
        </div>
        {children}
      </div>
    </div>
  );
})``;

export default CaseDetails;
