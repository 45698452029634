import { styled } from "styled-components";
import StatusIndicator from "../../pages/Requests/components/StatusIndicator.js";
import { Link } from "react-router-dom";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const RequestName = styled(Link)`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 600;
  width: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
    text-decoration: underline;

    svg {
      visibility: visible;
    }
  }

  svg {
    visibility: hidden;
    font-size: 0.75rem;
  }
`;

const ColumnDefs = [
  {
    dataField: "request_name",
    caption: "Request Name",
    visible: true,
    render: (row) => {
      return (
        <RequestName
          to={`/requests/${row.relay_tenant.slug}/${row.request_id}`}
          onClick={(e) => e.stopPropagation()}
          title={`View Request "${row.request_name}"`}
        >
          {row.request_name}
          <OpenInNewIcon />
        </RequestName>
      );
    },
  },
  {
    dataField: "client_ref_number",
    caption: "Reference Number",
    visible: true,
  },
  {
    dataField: "status",
    caption: "Request Status",
    visible: true,
    render: (data) => {
      return <StatusIndicator status={data.status} />;
    },
  },
  {
    dataField: "request_id",
    caption: "Request ID",
    visible: false,
  },
  {
    dataField: "created_at",
    caption: "Submitted On",
    dataType: "date",
    format: {
      type: "MM-DD-YYYY",
    },
    visible: true,
  },
  {
    dataField: "requestor.full_name",
    caption: "Requested By",
    visible: false,
    render: (data) => {
      return (
        <div>
          <div>{data.requestor.full_name}</div>
          <div style={{ color: "#888" }}>{data.requestor.email}</div>
        </div>
      );
    },
  },
  {
    dataField: "requestor.org",
    caption: "Request Organization",
    visible: false,
  },
  {
    dataField: "relay_tenant.org",
    caption: "Submitted To",
    visible: true,
  },
];

export default ColumnDefs;
