import { Modal } from "@mui/material";
import { styled } from "styled-components";
import ToolBarItems from "../ToolBarItems.js";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  gap: 5;
  align-items: center;
  margin-bottom: 20px;
  svg {
    margin-right: 10px;
  }
`;

const SubTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 20px;
  //   margin-top: 10px;
  //   color: ${(props) => props.theme.palette.text.secondary};
`;

const ModalContent = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50vw;
  max-height: 90vh;
  background-color: ${(props) => props.theme.palette.background.default};
  position: fixed;
  left: 50%;
  top: 10%;
  transform: translate(-50%, -10%);
  padding: 30px;
  outline: none;
  overflow-y: auto;
`;

const FeatureTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ItemHighlight = styled.span`
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: 600;
`;

const ModalBody = styled.div`
  margin-bottom: 40px;
`;

export const UpdateSerialNumber = 5001;

const UpdatesModal = ({ handleClose, open, showCheckbox = true }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    const checkState = event.target.checked;

    if (checkState)
      localStorage.setItem(
        "showUpdates",
        JSON.stringify({ UpdateSerialNumber, showUpdates: false })
      );
    else
      localStorage.setItem(
        "showUpdates",
        JSON.stringify({ UpdateSerialNumber, showUpdates: true })
      );
    setChecked(checkState);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus={true}
      style={{
        userSelect: "none",
        zIndex: 1400,
      }}
    >
      <ModalContent>
        <Title>
          <AutoAwesomeOutlinedIcon color="primary" /> Latest Updates
        </Title>
        <ModalBody>
          <SubTitle>Here are the latest updates for Relay!</SubTitle>
          <FeatureTitle>Expanded Request Features</FeatureTitle>
          You can now copy and edit items in your requests.
          <ul>
            <li>Copy evidence items when adding to requests.</li>
            <li>Edit evidence items added to requests.</li>
            <li>Edit people added to requests.</li>
          </ul>
          <FeatureTitle>Expanded Request Linked Contacts</FeatureTitle>
          Relay now allows more robust linked contacts handling.
          <ul>
            <li>Add linked contacts after request has been submitted</li>
            <li>Edit linked contacts after request has been submitted.</li>
          </ul>
          <FeatureTitle>Updated User Interface</FeatureTitle>
          <ul>
            <li>
              Most elements of the UI have been updated for an overall better
              expierience.
            </li>
            <li>
              Make sure to use helpful features such as the{" "}
              <ItemHighlight>table column selector</ItemHighlight>,{" "}
              <ItemHighlight>table search</ItemHighlight>, and{" "}
              <ItemHighlight>table export</ItemHighlight> features.
            </li>
            <li>
              Also pay attention to the{" "}
              <ItemHighlight>page selector</ItemHighlight> at the bottom right
              of your requests table.
            </li>
            <li>
              These features will help you navigate around Relay and help you
              find any requests you need to keep up with.
            </li>
            <li>
              *** Access your request by clicking on the request name in the
              "Request Name" column - it's highlighted in blue.
            </li>
          </ul>
          <FeatureTitle>Request Editing</FeatureTitle>
          <ul>
            <li>You can now edit a request after it has been submitted.</li>
            <li>
              Important: Once you forensic partner has accepted the request and
              created a case, the request can no longer be edited.
            </li>
            <li>
              With this update, you can now make the following changes to a
              submitted request:
              <ul>
                <li>
                  <ItemHighlight>Edit Request Details</ItemHighlight>
                </li>
                <li>
                  <ItemHighlight>
                    Add, Remove, or Edit Evidence Items
                  </ItemHighlight>
                </li>
                <li>
                  <ItemHighlight>Add or Remove Documents</ItemHighlight>
                </li>
                <li>
                  <ItemHighlight>Add, Remove, or Edit People</ItemHighlight>
                </li>
              </ul>
            </li>
            <li>
              This feature should now allow you to provide any requested items
              to your forensic partner using Relay.
            </li>
          </ul>
          <FeatureTitle>Request Comments</FeatureTitle>
          <ul>
            <li>A new commenting system has been added to requests.</li>
            <li>
              Use the comment system to communicate with your forensic partner
              about the request.
            </li>
            <li>
              Be sure to use the <ItemHighlight>"Mention"</ItemHighlight> system
              to alert users to your comment by using the{" "}
              <ItemHighlight>"@"</ItemHighlight> symbol in the comment editor.
            </li>
            <li>
              When you "Mention" someone in your comment, they will be notified
              by email, and receive your comment by email.
            </li>
          </ul>
        </ModalBody>
        {showCheckbox && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 40,
            }}
          >
            <Checkbox
              color="primary"
              // defaultChecked={false}
              checked={checked}
              onChange={handleChange}
            />
            <span style={{ fontWeight: 500 }}>
              Don't show this message again.
            </span>
          </div>
        )}
        <ToolBarItems
          submitText="Close"
          onSubmit={handleClose}
          showCancel={false}
          style={{ marginTop: 20 }}
        />
      </ModalContent>
    </Modal>
  );
};

export default UpdatesModal;
